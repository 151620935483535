<template>
  <div class="my-4">
    <b-row class="mx-0">
      <b-col md="8" offset-md="2">
        <b-card
          header-tag="header"
          header-bg-variant="info"
          header-text-variant="white"
        >
          <template #header>
            <h6 class="mb-0">My Info</h6>
          </template>
          <b-card-body>
            <b-row>
              <b-col md="2" offset-md="1">
                <label for="input-live"><strong>Name :</strong></label>
              </b-col>
              <b-col md="8" sm="11">
                <p style="margin-bottom: 0">{{ userDetails.name }}</p>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2" offset-md="1">
                <label for="input-live"><strong>UserName:</strong></label>
              </b-col>
              <b-col md="8" sm="11">
                <p style="margin-bottom: 0">{{ userDetails.username }}</p>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2" offset-md="1">
                <label for="input-live"><strong>Email Id:</strong></label>
              </b-col>
              <b-col md="8" sm="11">
                <p style="margin-bottom: 0">{{ userDetails.email }}</p>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2" offset-md="1">
                <label for="input-live"><strong>Mobile No. :</strong></label>
              </b-col>
              <b-col md="8" sm="11">
                <p style="margin-bottom: 0">{{ userDetails.mobile }}</p>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="2" offset-md="1">
                <label for="input-live"><strong>Date of Birth :</strong></label>
              </b-col>
              <b-col md="8" sm="11">
                <p style="margin-bottom: 0">
                  {{ userDetails.dob | timeFilter }}
                </p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <br />

        <b-card
          header-tag="header"
          header-bg-variant="info"
          header-text-variant="white"
        >
          <template #header>
            <h6 class="mb-0">Reset Password</h6>
          </template>
          <b-form @submit="submit" id="reset-pwd-form">
            <b-card-body>
              <b-button
                variant="primary"
                class="m-4"
                v-if="!passwordSent"
                @click="authenticate"
                >Reset Password</b-button
              >
              <p v-else>
                A link to reset you password has been sent to your email id.
              </p>
            </b-card-body>
          </b-form>
        </b-card>

        <b-card
          header-tag="header"
          header-bg-variant="info"
          header-text-variant="white"
        >
          <template #header>
            <h6 class="mb-0">Delete Account</h6>
          </template>
          <b-form @submit="submit" id="delete-account-form">
            <b-card-body>
              <div class="my-4">
                <b-button
                  variant="danger"
                  class="mx-4"
                  v-if="!showDeleteOption"
                  @click="showDeleteOption = true"
                  >Delete Account</b-button
                >
              </div>
              <b-row class="my-2" v-if="showDeleteOption">
                <b-col md="3" offset-md="2">
                  <label for="password-old">Please enter you password:</label>
                </b-col>
                <b-col md="5">
                  <b-form-input
                    v-model="password"
                    type="password"
                    aria-required="true"
                    placeholder="Enter Password"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>
              <div class="my-4">
                <b-button
                  variant="primary"
                  class="mx-4"
                  v-if="showDeleteOption"
                  :disabled="password == ''"
                  @click="authenticateForDelete"
                  >Continue</b-button
                >
              </div>
            </b-card-body>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="deleteConfirmModal"
      title="Are you sure you want to leave us?"
      header-bg-variant="info"
      header-text-variant="default"
      centered
      no-close-on-backdrop
    >
      <b-container fluid>
        <div v-if="!deletedSucessfully">
          <p>This action your profile will be deleted permanently.</p>
          <p>
            If you have any any grievances please write us at
            nisharga.info@gmail.com We will try to fix it as soon as possible.
            You are like a member to our family and we started missing you
            already.
          </p>
          <p>Do you wish to Continue to delete your profile?</p>
        </div>
        <div v-else>
          <p>
            Account deleted successfully. We will always have a special place
            for you in our heart. You will be redirected shortly.
          </p>
        </div>
      </b-container>

      <template #modal-footer v-if="!deletedSucessfully">
        <b-button
          size="md"
          variant="outline-primary"
          @click="deleteConfirmModal = false"
        >
          Cancel
        </b-button>
        <b-button v-if="isDeleting" variant="danger" disabled>
          Deleting...
        </b-button>
        <b-button
          v-else
          size="md"
          variant="outline-danger"
          @click="deleteProfile()"
        >
          Delete
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/src/moment";
import {
  BCard,
  BCardBody,
  BForm,
  BFormInput,
  BButton,
  BModal,
} from "bootstrap-vue";

export default {
  name: "Settings",

  components: { BCard, BCardBody, BForm, BFormInput, BButton, BModal },

  data() {
    return {
      passwordSent: false,
      oldPassword: "",
      password: "",
      showDeleteOption: false,
      deleteConfirmModal: false,
      isDeleting: false,
      showDeleteModal: false,
      deletedSucessfully: false,
    };
  },

  computed: {
    ...mapGetters(["userDetails"]),
  },

  methods: {
    ...mapActions(["signIn", "forgotPassword", "signOut", "deleteUserProfile"]),

    async submit(event) {
      event.preventDefault();
    },

    async authenticate() {
      try {
        await this.forgotPassword({ email: this.userDetails.email });
        this.passwordSent = true;
      } catch (e) {
        this.passwordSent = false;
        this.$bvToast.toast("An error has occured. Please try again.", {
          title: "Error Occured",
          autoHideDelay: 3000,
          variant: "danger",
          solid: true,
        });
        console.error(e);
      }
    },

    async authenticateForDelete() {
      try {
        let isAuthenticated = await this.signIn({
          username: this.userDetails.username,
          password: this.password,
        });

        if (isAuthenticated) {
          this.deleteConfirmModal = true;
        } else {
          this.$bvToast.toast("Authentication Failed! Please try again later", {
            title: "Authentication Failed",
            autoHideDelay: 3000,
            variant: "danger",
            solid: true,
          });
        }
      } catch (e) {
        this.$bvToast.toast("Please enter correct password", {
          title: "Incorrect Password",
          autoHideDelay: 3000,
          variant: "danger",
          solid: true,
        });
        console.error(e);
      }
    },

    async deleteProfile() {
      try {
        this.isDeleting = true;
        await this.deleteUserProfile({
          username: this.userDetails.username,
          password: this.password,
        });

        this.isDeleting = false;
        this.deletedSucessfully = true;

        setTimeout(() => {
          this.signOut();
        }, 3000);
      } catch (e) {
        this.$bvToast.toast("Deletion failed Please try again later", {
          title: "Deletion Failed",
          autoHideDelay: 3000,
          variant: "danger",
          solid: true,
        });
        this.isDeleting = false;
        console.error(e);
      }
    },
  },

  filters: {
    timeFilter(time) {
      return moment(time).format("DD-MMM-YYYY");
    },
  },
};
</script>

<style scoped></style>
